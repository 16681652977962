<script setup lang="ts">
const props = defineProps<{
  modelValue?: string | null
  count: number
  autofocus?: boolean
}>()

const emit = defineEmits(['update:model-value', 'close'])

const iRefs = ref<any[]>([])

const digits = computed({
  get: () => {
    const holder = new Array(props.count).fill('')
    return holder.map((_, index) => {
      return props.modelValue?.charAt(index) ?? ''
    })
  },
  set: (value) => {
    emit('update:model-value', value.join(''))
  }
})

const onValueChange = (e: Event) => {
  const target = e.target as HTMLInputElement
  const index = parseInt(target.dataset.id ?? '0')
  target.value = target.value.replace(/[^\d]/gi, '')
  if (target.value === '' || !target.validity.valid) {
    return
  }
  let next
  const value = target.value

  const newValues = [...digits.value]

  if (value.length > 1) {
    let nextIndex = value.length + index - 1
    if (nextIndex >= props.count) {
      nextIndex = props.count - 1
    }
    next = iRefs.value[nextIndex]
    const split = value.split('')
    split.forEach((item, i) => {
      const cursor = index + i
      if (cursor < props.count) {
        newValues[cursor] = item
      }
    })
    digits.value = newValues
  } else {
    next = iRefs.value[index + 1]
    newValues[index] = value
    digits.value = newValues
  }

  if (next) {
    const element = next as HTMLInputElement // this.$refs[next][0]
    element.focus()
    element.select()
  }
}
const onPaste = (e: ClipboardEvent) => {
  const paste = (e.clipboardData || (window as any).clipboardData).getData('text')
  if (paste.length === 0) return
  const newValues = digits.value.map((v, index) => {
    return paste.charAt(index) ?? v
  })
  e.preventDefault()
  digits.value = newValues
  setTimeout(() => {
    iRefs.value[digits.value.length - 1]?.focus()
  }, 100)
}
const onFocus = (e: FocusEvent) => {
  const target = e.target as HTMLInputElement
  target.select()
}
const onKeyDown = (e: KeyboardEvent) => {
  const target = e.target as HTMLInputElement
  const index = parseInt(target.dataset.id ?? '0')
  const prevIndex = index - 1
  const nextIndex = index + 1
  const prev = iRefs.value[prevIndex] as HTMLInputElement | undefined
  const next = iRefs.value[nextIndex] as HTMLInputElement | undefined

  switch (e.code) {
    case 'Backspace': {
      e.preventDefault()
      const vals = [...digits.value]
      if (digits.value[index]) {
        vals[index] = ''
        digits.value = vals
      } else if (prev) {
        vals[prevIndex] = ''
        prev.focus()
        digits.value = vals
      }
      break
    }
    case 'ArrowLeft':
      e.preventDefault()
      if (prev) {
        prev.focus()
      }
      break
    case 'ArrowRight':
      e.preventDefault()
      if (next) {
        next.focus()
      }
      break
    case 'ArrowUp':
    case 'ArrowDown':
      e.preventDefault()
      break
    default:
      break
  }
}

onMounted(() => {
  iRefs.value?.[0]?.focus()
})
</script>
<template>
  <div class="flex items-center justify-center space-x-2">
    <input
      v-for="(v, index) in digits"
      :key="index"
      ref="iRefs"
      class="focus:ring-primary-500 form-input relative block w-11 flex-none rounded-md border-0 bg-white text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-75"
      inputmode="numeric"
      :value="v"
      :data-id="index"
      maxLength="1"
      @input="onValueChange"
      @focus="onFocus"
      @keydown="onKeyDown"
      @paste="onPaste"
    />
  </div>
</template>
